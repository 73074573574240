import React, { useState, useCallback } from 'react';
import { FormattedNumber } from 'react-intl';
import { identity } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { Text } from '@welcome-ui/text';
import { Flex } from '@welcome-ui/flex';
import { Link } from '@welcome-ui/link';
import { x } from '@xstyled/styled-components';

import { RadioButtonGroup, Loader, PageTitle } from '../components';
import {
  DrillDownButton,
  useWasteSankeyData,
  WasteFlowSankey,
} from '../components/sankey';
import { useOrgName, useFilter } from '../hooks';

const getMonthlyWaste = gql`
  ${WasteFlowSankey.fragment}
  query WastePredictions($orgName: String!, $filter: ReportInput!) {
    organization(name: $orgName) {
      id
      report(filter: $filter) {
        id
        aggregatedWaste {
          ...AllAggregatedWasteDataFragment
        }
      }
      yearlyPredictions {
        waste
        CO2
      }
    }
  }
`;

const Units = [
  {
    value: 'waste',
    label: 'Waste',
    description: 'waste',
    references: [
      {
        text: 'Waste Generation Calculator',
        href: 'https://www.zerowastedesign.org/waste-calculator/',
      },
    ],
  },
  {
    value: 'CO2',
    label: 'CO₂',
    description: 'CO₂ equivalencies',
    references: [
      {
        text: 'EPA CO₂ waste estimates',
        href: 'https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references',
      },
      {
        text: 'CO₂ ratio',
        href: 'https://ourworldindata.org/grapher/co-emissions-per-capita?tab=chart&country=USA~BGR',
      },
    ],
  },
];

/* istanbul ignore next */
const renameLandfillToAir = (s) => (s === 'Landfill' ? 'Air' : s);

/* istanbul ignore next */
export const PredictionsPage = () => {
  const orgName = useOrgName();
  const filter = useFilter();
  const { loading, data: queryData } = useQuery(getMonthlyWaste, {
    variables: { orgName, filter },
  });

  const [selectedUnit, setSelectedUnit] = useState(Units[0]);
  const [drillDownLabel, setDrillDownLabel] = useState(null);

  const aggregatedWaste = queryData?.organization?.report?.aggregatedWaste;
  const predictions = queryData?.organization?.yearlyPredictions;

  const totalWasteItems = aggregatedWaste?.total;
  const totalWasteTons =
    selectedUnit.value === 'CO2' ? predictions?.CO2 : predictions?.waste;
  const scale = totalWasteTons / totalWasteItems;
  const rename = selectedUnit.value === 'CO2' ? renameLandfillToAir : identity;

  const sankeyData = useWasteSankeyData(aggregatedWaste?.data, 'opportunity', {
    drillDownLabel,
    scale,
    rename,
  });

  const clearDrillDown = useCallback(() => setDrillDownLabel(null), []);

  const handleSelectTarget = useCallback(
    (unit) => {
      clearDrillDown();
      setSelectedUnit(unit);
    },
    [clearDrillDown],
  );

  if (loading) return <Loader />;

  return (
    <Flex w="100%" direction="column" p="lg">
      <PageTitle>Environmental Impact Predictions</PageTitle>
      <RadioButtonGroup
        options={Units}
        value={selectedUnit}
        onChange={handleSelectTarget}
      />
      <Flex align="center" justify="space-between">
        <Text>
          Environmental impact:&nbsp;
          <strong>
            <FormattedNumber value={totalWasteTons} maximumFractionDigits={2} />
          </strong>
          &nbsp;tons of {selectedUnit.description} per year
        </Text>
        <DrillDownButton label={drillDownLabel} onClick={clearDrillDown} />
      </Flex>
      <WasteFlowSankey data={sankeyData} onNodeClick={setDrillDownLabel} />

      <Text mb="0">References:</Text>
      <x.ol mt="sm" fontSize="sm">
        {selectedUnit.references.map(({ text, href }) => (
          <li key={href}>
            <Link href={href} target="_blank" isExternal>
              {text}
            </Link>
          </li>
        ))}
      </x.ol>
    </Flex>
  );
};
